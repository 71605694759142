<template>
  <div>
    <b-card>
      <div class="d-flex">
        <p class="my-auto mr-auto">
          <i :class="$route.meta.iconClass"></i>
          <span
            style="font-size: 22px; color: #7f7f7f"
            class="font-weight-light"
          >
            Fiche Concept :
          </span>
          <span style="font-size: 25px" class="font-weight-bold">{{
            CONCEPT.name
          }}</span>
        </p>
        <div class="row mb-4">
          <div class="col-12">
            <modal-actions
              @close="
                $router.go(
                  $store.getters['routerHistory/fromCreation'] ? -2 : -1
                )
              "
            />
          </div>
        </div>
      </div>
      <div class="shdow">
        <div
          class="d-flex flex-wrap justify-content-between align-items-center"
        >
          <p
            class="h4 text-secondary my-auto mr-auto"
            style="margin: 5.656px 0 !important"
          >
            Informations générales
          </p>
          <a
            v-if="(isAdmin || isSuper || isArchitect) && !editMode"
            style="font-size: 30px; color: #007bff"
            @click.prevent="editMode = !editMode"
            ><i class="mdi mdi-file-document-edit"></i
          ></a>
          <div
            v-else-if="isAdmin || isSuper || isArchitect"
            class="m-0 d-flex p-0"
          >
            <b-button
              style="min-width: 120px"
              type="submit"
              variant="secondary"
              pill
              @click="updateItem"
              :disabled="submitingForm"
            >
              <b-spinner
                v-if="submitingForm"
                small
                class="text-white"
                label="Loading..."
              ></b-spinner>
              Confirmer</b-button
            >
            <b-button
              @click="editMode = !editMode"
              pill
              style="background-color: #adb5bd; border-color: #adb5bd"
              >Annuler</b-button
            >
          </div>
        </div>
        <div class="col-12 p-0">
          <hr class="bg-secondary mb-3 mt-1" />
        </div>
        <div v-if="!editMode" class="row">
          <div class="col-12">
            <div class="my-4">
              <span>Concept Métier : </span>
              <span class="font-weight-bold"> {{ CONCEPT.name }} </span>
            </div>
            <div class="my-4">
              <span class="d-block mb-3">Description:</span>
              <span class="font-weight-bold"> {{ CONCEPT.description }} </span>
            </div>
          </div>
        </div>

        <div v-else class="row">
          <div class="col-12">
            <b-form-group
              label-cols="12"
              label="Nom"
              label-class="font-weight-bold"
            >
              <b-form-input
                v-model="newConcept.name"
                :class="{
                  'is-invalid':
                    $v.newConcept.name.$error && $v.newConcept.name.$dirty,
                }"
                type="text"
              ></b-form-input>
              <!-- ERROR MESSAGES -->
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.newConcept.name.$dirty"
              >
                {{
                  !$v.newConcept.name.required
                    ? "champ obligatoire"
                    : !$v.newConcept.name.minLength
                    ? `Le champ doit contenir au moins ${$v.newConcept.name.$params.minLength.min} caractères.`
                    : ""
                }}
              </span>
              <!--  -->
            </b-form-group>
            <b-form-group
              label-cols="12"
              label="Description"
              label-class="font-weight-bold"
            >
              <b-form-textarea
                rows="6"
                v-model="newConcept.description"
                placeholder="Description"
              ></b-form-textarea>
            </b-form-group>
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import ModalActions from "@/components/custom/ModalActions.vue";
import Swal from "sweetalert2";
import { required, minLength } from "vuelidate/lib/validators"; //validation
import { mapGetters } from "vuex";

export default {
  components: {
    ModalActions,
  },
  data: () => ({
    editMode: false,
    submitingForm: false,
    newConcept: {
      name: "",
      description: "",
    },
  }),
  validations: {
    newConcept: {
      name: {
        required,
        minLength: minLength(5),
      },
    },
  },
  methods: {
    updateItem() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        (this.submitingForm = true),
          this.$store
            .dispatch("concept/updateConcept", {
              id: this.$route.params.id,
              ...this.newConcept,
            })
            .then(() => {
              this.$store
                .dispatch("concept/fetchConcept", this.$route.params.id)
                .then(() => {
                  Swal.fire("Concept est mis à jour !", "", "success");
                  this.editMode = false;
                });
            });
      }
    },
  },
  created() {
    this.$store
      .dispatch("concept/fetchConcept", this.$route.params.id)
      .then(() => {
        this.$store.dispatch("loader/toggleLoading", false);
      });
  },
  computed: {
    ...mapGetters("concept", ["CONCEPT"]),
    ...mapGetters(["isAdmin", "isSuper", "isArchitect"]),
  },
  watch: {
    CONCEPT: function () {
      this.newConcept = {
        ...this.newConcept,
        name: this.CONCEPT.name,
        description: this.CONCEPT.description,
      };
    },
  },
};
</script>

<style></style>
